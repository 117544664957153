import styled from 'styled-components'
import { H3 } from '../../atoms/Text'
import { FColG16, FRowG16 } from '../../atoms/Layout'

export const Wrapper = styled(FColG16)`
    height: 360px;
    width: 500px;
    position: relative;
`
export const Overlay = styled(FRowG16)`
    background-color: rgba(255, 255, 255, 0.7);
    padding: 24px 36px;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    align-items: center;
`
export const Title = styled(H3)`
    font-style: normal;
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 1.5rem;
    color: #000000;
`
export const Description = styled(Title)`
    font-size: 1.25rem;
    font-weight: normal;
`
