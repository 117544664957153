import { Bg } from '../../atoms/Bg'
import styled from 'styled-components'
import Content from '../../atoms/Content'
import { sBreakpoints } from '../../constants'
import { H2 } from '../../atoms/Text'
import { FRowG16 } from '../../atoms/Layout'

export const SBg = styled(Bg)`
    align-items: center;
    overflow: hidden;
`

export const SContent = styled(Content)`
    margin-bottom: 128px;
`

export const Title = styled(H2)`
    margin-top: 128px;
    margin-left: 50px;

    ${sBreakpoints.tablet} {
        margin-top: 64px;
        margin-left: 0;
        text-align: center;
    }
`

export const Relative = styled.div`
    position: relative;
`

export const SquareWeebCutWrapper = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(80px, -80px);
    color: #1c211f;
    opacity: 0.25;
`

export const FeatureValueWrapper = styled(FRowG16)`
    align-items: center;
`
