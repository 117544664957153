import React from 'react'
import * as S from '../CountWithUs/styled'
import SectionTitle from '../../molecules/SectionTitle'
import { ButtonBlack } from '../../atoms/Buttons/Button'
import ThinArrow from '../../atoms/Icons/ThinArrow'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { useRenderBreakpoints } from '../../utils/hooks/render'
import { BgProps, colorFilters } from '../../atoms/Bg'

export type RenewIsHardProps = {
    bgColor?: BgProps['color']
}

export const RenewIsHard = React.memo<RenewIsHardProps>(({ bgColor = 'white' }) => {
    const { t } = useTranslation(['index', 'common'])
    const { onTablet } = useRenderBreakpoints()
    return (
        <S.SBg color={bgColor} useHPadding={true}>
            <S.SContent variant={'transparent'} useMaxWidth={true} expanded={true}>
                <SectionTitle
                    title={[t('countWithUs.title.part1'), t('countWithUs.title.part2')]}
                    description={t('countWithUs.description')}
                />
                <S.ContentWrapper>
                    <S.SndContentWrapper>
                        {onTablet(
                            ({ matches: tablet }) => (
                                <S.SButtonWArrow
                                    as={ButtonBlack}
                                    size={!tablet ? 'large' : undefined}
                                >
                                    {t('common:scheduleACall')}
                                    <ThinArrow />
                                </S.SButtonWArrow>
                            ),
                            { mode: 'handle-both' },
                        )}

                        <S.InfoSectionWrapper>
                            <S.SpiderWeeb
                                style={{
                                    filter: colorFilters[bgColor] + ' brightness(0.9)',
                                }}
                            />
                            <S.SInfoSection color={'blue'} icon={'message'}>
                                <br />
                                <S.InfoSectionTitle>
                                    {t('countWithUs.emailUs')}
                                </S.InfoSectionTitle>
                                <S.InfoSectionText>
                                    conamerservices@gmail.com
                                </S.InfoSectionText>
                            </S.SInfoSection>
                            <S.SInfoSection color={'purple'} icon={'call'}>
                                <br />
                                <S.InfoSectionTitle>
                                    {t('countWithUs.callUs')}
                                </S.InfoSectionTitle>
                                <S.InfoSectionText>+58 (424) 1426532</S.InfoSectionText>
                            </S.SInfoSection>
                        </S.InfoSectionWrapper>
                    </S.SndContentWrapper>
                </S.ContentWrapper>
            </S.SContent>
        </S.SBg>
    )
})

RenewIsHard.displayName = 'RenewIsHard'

export default RenewIsHard
