import styled from 'styled-components'
import * as WhyUs from '../WhyUs/styled'
import { FColG16, FRowTColG16 } from '../../atoms/Layout'
import { sBreakpoints } from '../../constants'
import spiderweeb from '../../images/spiderweeb.png'
import { Bg } from '../../atoms/Bg'
import InfoSection from '../../atoms/InfoSection'
import { ButtonWArrow } from '../../atoms/Buttons/Button'

export const CarouselBg = styled(Bg)`
    padding-bottom: 128px;
`
export const CarouselWrapper = styled.div`
    width: 100%;
`

export const SBg = WhyUs.SBg
export const SContent = WhyUs.SContent

export const ContentWrapper = styled(FColG16)`
    margin-top: 32px;
`

export const SndContentWrapper = styled(FColG16)`
    align-items: center;
    margin-left: 64px;
    margin-right: 64px;
    ${sBreakpoints.tablet} {
        margin-left: 0;
        margin-right: 0;
    }
`

export const InfoSectionWrapper = styled(FRowTColG16)`
    width: 100%;
    margin-top: 100px;
    position: relative;
`

export const SInfoSection = styled(InfoSection)`
    flex: 1;
    z-index: 1;
`

export const SpiderWeeb = styled.img.attrs({
    src: spiderweeb,
})`
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    transform: translate(-65%, -45%) rotate(90deg);
`

export const InfoSectionTitle = styled.b`
    ${sBreakpoints.laptop} {
        font-size: 1rem;
    }
`
export const InfoSectionText = styled.span`
    ${sBreakpoints.laptop} {
        font-size: 0.875rem;
    }
`

export const SButtonWArrow = styled(ButtonWArrow)`
    z-index: 1;
`
