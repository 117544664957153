import styled from 'styled-components'
import { FRow } from '../Layout'
import { CircleButtonBlack } from '../Buttons/Button'
import ThinArrow from '../Icons/ThinArrow'

export const NavButton = styled(CircleButtonBlack)`
    pointer-events: none;
`
export const Wrapper = styled(FRow)`
    overflow: hidden;
    position: relative;
    & ${NavButton} {
        opacity: 0;
    }
    &:hover ${NavButton} {
        opacity: 0.4;
    }
`
export const Floating = styled(FRow)`
    align-items: center;
    justify-content: center;
    position: absolute;
    inset: 0;
    min-width: 150px;
    z-index: 5;
`

export const FloatingLeft = styled(Floating)`
    right: unset;
`

export const FloatingRight = styled(Floating)`
    left: unset;
`

export const SArrow = styled(ThinArrow)`
    font-size: 40px;
    font-weight: normal;
`

export const NavButtonLeft = styled(NavButton)`
    transform: scaleX(-1);
`
