import React from 'react'
import { FColG8, FExpand } from '../../atoms/Layout'
import { CircleButtonBlack } from '../../atoms/Buttons/Button'
import { SArrow } from '../../atoms/AutoCarousel/styled'
import { Description, Overlay, Title, Wrapper } from './styled'

export type CollectionCardProps = {
    image: string
    title: string
    description: string
    onClick?(): void
}

export const CollectionCard: React.FC<CollectionCardProps> = React.memo(
    ({ image, title, description, onClick }) => {
        return (
            <Wrapper
                style={{
                    background: `url(${image})`,
                }}
            >
                <Overlay>
                    <FColG8>
                        <Title as={'h4'}>{title}</Title>
                        <Description as={'h5'}>{description}</Description>
                    </FColG8>
                    <FExpand />
                    <CircleButtonBlack onClick={onClick}>
                        <SArrow />
                    </CircleButtonBlack>
                </Overlay>
            </Wrapper>
        )
    },
)

CollectionCard.displayName = 'CollectionCard'

export default CollectionCard
