import styled from 'styled-components'
import { FRow } from '../Layout'

export const BigStep = styled(FRow)`
    width: 80px;
    height: 80px;
    background-color: rgb(27, 34, 43);
    color: rgb(255, 255, 255);
    font-size: 2rem;
    font-weight: bold;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
`
