import React from 'react'
import { FColG16 } from '../../atoms/Layout'
import { H2, H3 } from '../../atoms/Text'
import { BigStep } from '../../atoms/BigStep'
import girlWithIphone from '../../images/girl-with-iphone.jpg'
import girlWithTablet from '../../images/girl-with-tablet.jpg'
import guyAndGirlUsingTablet from '../../images/guy-and-girl-using-tablet.jpg'
import justATablet from '../../images/just-a-tablet.jpg'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import EstimateButton from '../EstimateButton'
import Stepper from '../../molecules/Stepper'
import wallPaint from '../../images/wall-paint.jpg'
import step3 from '../../images/steps/step3.jpg'
import step4 from '../../images/steps/step4.jpg'
import step5 from '../../images/steps/step5.jpg'
import { ButtonBlack, ButtonWArrow } from '../../atoms/Buttons/Button'
import ThinArrow from '../../atoms/Icons/ThinArrow'
import SectionTitle from '../../molecules/SectionTitle'
import { ImageF } from '../../atoms/Image'
import {
    ReadyToStart,
    SBg,
    ScheduleACallWrapper,
    SContent,
    SEstimateTableSample,
    SpaceV100,
    SpaceV128T64,
    Step1,
    Step2,
    Step3,
    StepContent,
    StepTitleWrapper,
} from './styled'
import { useRenderBreakpoints } from '../../utils/hooks/render'
import { BgProps } from '../../atoms/Bg'

const stepperSteps = [wallPaint, justATablet, step3, step4, step5]

const getStep = ({
    step,
    title,
    description,
    afterDescription,
    content,
    titleWidth = '75%',
}: Partial<{
    step: number
    title: string
    description: string
    afterDescription: React.ReactNode
    content: React.ReactNode
    titleWidth: string | number
}>) => {
    return (
        <React.Fragment>
            <StepTitleWrapper style={{ width: titleWidth }}>
                <BigStep>{step}</BigStep>
                <FColG16>
                    <div>
                        <H2 style={{ margin: 0 }}>{title}</H2>
                    </div>
                    <div>
                        <H3>{description}</H3>
                    </div>
                    {afterDescription}
                </FColG16>
            </StepTitleWrapper>
            <StepContent>{content}</StepContent>
        </React.Fragment>
    )
}

export type HowItWorksProps = {
    bgColor?: BgProps['color']
    hideTitle?: boolean
}

export const HowItWorks: React.FC<HowItWorksProps> = React.memo(
    ({ bgColor = 'blue', hideTitle }) => {
        const { t } = useTranslation(['index', 'common'])
        const { onTablet } = useRenderBreakpoints()

        return (
            <SBg color={bgColor} useHPadding={true}>
                <SContent variant={'transparent'} useMaxWidth={true} expanded={true}>
                    {!hideTitle && (
                        <React.Fragment>
                            <SectionTitle
                                title={t('howItWorks.title')}
                                description={[
                                    t('howItWorks.content.part1'),
                                    t('howItWorks.content.part2'),
                                ]}
                            />
                            <SpaceV100 />
                        </React.Fragment>
                    )}

                    {getStep({
                        step: 1,
                        titleWidth: '50%',
                        title: t('common:getAnEstimate'),
                        description: t('howItWorks.steps.1.description'),
                        afterDescription: (
                            <Step1.AfterDescription>
                                {onTablet(
                                    ({ matches: tablet }) => (
                                        <EstimateButton
                                            size={tablet ? 'normal' : undefined}
                                        />
                                    ),
                                    { mode: 'handle-both' },
                                )}
                            </Step1.AfterDescription>
                        ),
                        content: (
                            <Step1.Content>
                                {onTablet(
                                    () => (
                                        <SEstimateTableSample />
                                    ),
                                    { mode: 'hide' },
                                )}
                                <ImageF src={girlWithIphone} />
                            </Step1.Content>
                        ),
                    })}
                    <SpaceV128T64 />
                    {getStep({
                        step: 2,
                        title: t('howItWorks.steps.2.title'),
                        description: t('howItWorks.steps.2.description'),
                        content: (
                            <Step2.Content>
                                <Step2.RelativeBase>
                                    {onTablet(
                                        () => (
                                            <React.Fragment>
                                                <Step2.SunLightWeeb />
                                                <Step2.GirlWithTabletWrapper>
                                                    <ImageF src={girlWithTablet} />
                                                </Step2.GirlWithTabletWrapper>
                                            </React.Fragment>
                                        ),
                                        { mode: 'hide' },
                                    )}
                                    <Step2.TwoImagesWrapper>
                                        {onTablet(
                                            ({ matches: tablet }) => (
                                                <ImageF
                                                    src={
                                                        tablet
                                                            ? girlWithTablet
                                                            : guyAndGirlUsingTablet
                                                    }
                                                />
                                            ),
                                            { mode: 'handle-both' },
                                        )}
                                        <ImageF src={justATablet} />
                                    </Step2.TwoImagesWrapper>
                                </Step2.RelativeBase>
                            </Step2.Content>
                        ),
                    })}
                    <SpaceV128T64 />

                    {getStep({
                        step: 3,
                        title: t('howItWorks.steps.3.title'),
                        description: t('howItWorks.steps.3.description'),
                        content: (
                            <Step3.Content>
                                <Stepper
                                    steps={stepperSteps.map((image, x) => ({
                                        image,
                                        title: t(
                                            `howItWorks.steps.3.steps.${x + 1}.title`,
                                        ),
                                        description: t(
                                            `howItWorks.steps.3.steps.${
                                                x + 1
                                            }.description`,
                                        ),
                                    }))}
                                />
                            </Step3.Content>
                        ),
                    })}
                    <SpaceV128T64 />
                    <ReadyToStart>
                        {t('readyToStart.part1')}
                        <br />
                        {t('readyToStart.part2')}
                        <br />
                        <ScheduleACallWrapper>
                            <ButtonWArrow as={ButtonBlack} size={'large'}>
                                {t('common:scheduleACall')}
                                <ThinArrow />
                            </ButtonWArrow>
                        </ScheduleACallWrapper>
                    </ReadyToStart>
                </SContent>
            </SBg>
        )
    },
)

HowItWorks.displayName = 'HowItWorks'

export default HowItWorks
