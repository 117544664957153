import React from 'react'
import * as S from '../CountWithUs/styled'
import AutoCarousel from '../../atoms/AutoCarousel'
import CollectionCard from '../../molecules/CollectionCard'
import { gallery, projectsObj } from '../../providers/GalleryProvider'
import { useNavigateNoScroll } from '../../utils/hooks/useNavigateNoScroll'
import { useProjectNavigation } from '../../providers/GalleryProvider/hooks/useProjectNavigation'

export type CollectionCarouselProps = {}

const collections = projectsObj.map((p) => {
    const proyName = p.name
    const projectGallery = gallery.filter((g) => {
        return g.projectName === proyName
    })
    return projectGallery[Math.floor(Math.random() * projectGallery.length)]
})

export const CollectionCarousel = React.memo<CollectionCarouselProps>(({}) => {
    const { toggle } = useProjectNavigation()
    return (
        <S.CarouselBg color={'white'}>
            <S.CarouselWrapper>
                <AutoCarousel
                    count={collections.length}
                    render={(index) => {
                        const col = collections[index]
                        return (
                            <CollectionCard
                                key={index}
                                image={col.src ?? ''}
                                title={col.projectName ?? ''}
                                description={'Caracas, Venezuela'}
                                onClick={() => {
                                    if (col.projectName) toggle(col.projectName, true)
                                }}
                            />
                        )
                    }}
                />
            </S.CarouselWrapper>
        </S.CarouselBg>
    )
})

CollectionCarousel.displayName = 'CollectionCarousel'

export default CollectionCarousel
